import "./css/home.css";
// import { Link } from "react-router-dom";
export default function Home() {
  return (
    <section className="home-container">
      <div className="home">
      
            <h1 id="home-heading">Welcome </h1>
            <h1 id="home-heading1"> to </h1>
            <h1 id="home-heading2"> Rawdah Institute</h1>
       
      </div>
     
    </section>
  );
}



    